<template>
  <div class="section1">
    <div class="section bordered">
      <div class="content" data-section-name="emailInfo">
        <h2>Update Email</h2>
        <b-form >
          <b-row class="mt-4">
            <b-col lg="6">
              <b-row class="mt-4">
                <b-col lg="3">
                  <label class="thick" for="currentEmail">Current Email</label>
                </b-col>
                <b-col lg="9">
                  <b-form-input v-model.trim="details.email" label="Current Email" name="currentEmail" :disabled="true"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col lg="6">
              <b-row class="mt-4">
                <b-col lg="3">
                  <label class="thick" for="newEmail">New Email <span class="arm-required">*</span></label>
                </b-col>
                <b-col lg="9">
                  <b-form-input v-model.trim="newEmail" label="New Email" name="newEmail" :required=required :state="validateEmailMsg" @keyup="validateEmail()" @blur="validateEmail()"></b-form-input>
                  <b-form-invalid-feedback id="email-feedback" class="thick">{{errorMessage}}</b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col lg="6">
              <b-row class="mt-4">
                <b-col lg="3">
                  <label class="thick" for="verifyEmail">Verify Email<span class="arm-required">*</span></label>
                </b-col>
                <b-col lg="9">
                  <b-form-input v-model.trim="verifyEmail" label="Verify Email" name="verifyEmail" :required=required :state="sameEmails"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col lg="6">
              <b-row class="mt-4">
                <b-col lg="3">
                  <label class="thick" for="currentPassword">Current Password<span class="arm-required">*</span></label>
                </b-col>
                <b-col lg="9">
                  <b-form-input v-model.trim="currentPassword" label="Current Password" name="currentPassword" :required=required type="password"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col offset-lg="3" lg="6"><b-button @click="onSubmit" type="submit" block variant="primary" :disabled="!isSubmitAllowed"><font-awesome-icon icon="check" /> Submit</b-button></b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <b-row class="mt-4">
      <b-col lg="10">
        <b-alert :show="showMessage && updateFailed" variant="danger" class="thick">Sorry! We have encountered an error.
          If this problem persists, please contact us at accounts@arm.gov. Thank you.
        </b-alert>
        <b-alert :show="showMessage && !updateFailed" variant="success">
          <h3>Success!</h3>
          Your email has been successfully updated with ARM. You may
          continue to use the ARM Site.
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import Config from "../config";
  import {required} from "vuelidate/lib/validators";
  import { validationMixin } from "vuelidate";
  import axios from "axios";
  import {mapState} from "vuex";

  const emailValidator = Config.validations.emailValidator

  export default {
    name: "ResetEmail",
    mixins: [validationMixin],
    components: {

    },
    data() {
      return {
        currentEmail: "",
        newEmail: "",
        verifyEmail: "",
        required: true,
        errorMessage: null,
        isEmailUpdated: null,
        emailSectionValid: false,
        doesEmailExist: false,
        currentPassword: "",
        username: "",
        showMessage: false,
        updateFailed: false,
        submitted: false,
      }
    },
    watch: {
      validationObject: {
        handler(newVal, oldVal) {
          const a = JSON.parse(newVal)
          const b = JSON.parse(oldVal)
          Object.keys(a.$params).forEach(key => {
            if (a[key].$model !== b[key].$model) {
              this.$v[key].$touch()
              this.emailSectionValid = !this.$v.$invalid
            }
          })
        },
        deep: true,
      },
      $route: "init"
    },
    computed: {
      ...mapState("auth",["details"]),
      validationObject() {
        return JSON.stringify(this.$v)
      },
      isSubmitAllowed() {
        return this.emailSectionValid && this.sameEmails && !this.errorMessage && !this.submitted
      },
      sameEmails () {
        return this.emailsFilled() && (this.newEmail === this.verifyEmail) ? true : false
      },
      validateEmailMsg() {
        //if (this.isEmailUpdated) {
          if (this.errorMessage || !this.newEmail)
            return false
          else {
            var re = /^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$/igm;
            return !re.test(this.newEmail)
          }
        // }
        // else {
        //   return false
        // }
      }
    },
    mounted() {
      this.init()
    },
    validations: {
      newEmail: {
        required,
        emailValidator,
      },
      currentPassword: {
        required
      }
    },
    methods: {
      init() {
        if (this.$route.params.id) {
          this.username = this.$route.params.id
        }
        if (this.details) {
          this.currentEmail = this.details.email
        }

      },
      async validateEmail() {
        //this.isEmailUpdated = true
          const result = await this.$store.dispatch("profile/validateEmail", this.newEmail)
          this.updateOptions(result)
      },
      emailsFilled() {
        return (this.newEmail !== '' && this.verifyEmail !== '')
      },
      updateOptions(response) {
          if (response.accountType) {
            this.errorMessage = "Account already exists"
          } else if (response.errorMessage) {
            this.errorMessage =  response.errorMessage
          } else {
            this.errorMessage = ""
          }
      },
      onSubmit(ev) {
        this.submitted = true
        ev.preventDefault()
        if (!this.sameEmails) {
          this.errorMsg = "The New and Verify Emails entered don't match."
          return false
        } else {
          var people = {
            userName: this.username
          }
          var payload = {
            people: people,
            email: this.details.email,
            newEmail: this.newEmail
          }
          var self = this
          axios({
            method: "post",
            url: `${Config.auth.url}/armuserreg-api/account/update/email`,
            headers: {
              Authorization: `Basic ${btoa(`${this.username}:${this.currentPassword}`)}`
            },
            data: payload
          }).then((response) => {
            if (response.data === 'Succesfully updated the email!' && response.status === 200) {
              self.showMessage = true
              self.updateFailed = false
              this.$store.dispatch("auth/updateEmail", this.newEmail)
            } else {
              self.showMessage = true
              self.updateFailed = true
            }
          }).catch((error) => {
            console.log(error)
            self.showMessage = true
            self.updateFailed = true
          })
        }
      }
    }
  }
</script>
<style scoped>

</style>