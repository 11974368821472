<template>
    <b-container fluid>
        <TagHeader :title="title" :breadCrumbTitle="breadCrumbTitle"></TagHeader>
        <LoginContent />
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import LoginContent from "@/components/LoginContent"
    export default {
        name: "Login",
        components: {
          TagHeader,
            LoginContent
        },
        data() {
            return {
                title: "Account Management",
                breadCrumbTitle: "Connect with ARM"
            }
        }
    }
</script>

<style scoped>

</style>
