import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

import auth         from "./modules/auth"
import app         from "./modules/app"
import profile         from "./modules/profile"

export default new Vuex.Store ({

    modules: {
        auth,
        app,
        profile
    }

});
