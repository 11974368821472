<template>
    <div class="section2">
        <ValidationSection />
        <div class="section bordered">
            <h2>Subscriber Information</h2>
            <div class="content" data-section-name="newsletterinfo">
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick">First name<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="8">
                        <b-form-input
                          v-model.trim="people.nameFirst"
                          placeholder="First name ..."
                          :state="validateState('nameFirst')"
                          aria-describedby="feedback"
                          @keyup="createUsername"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          First name is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                        <div
                          v-if="validateState('nameFirst') && people.nameFirst.length === 1"
                          class="font-weight-bold"
                        >
                          Note: Please ensure you only intended to have a single character as your first name
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick">Last name<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="8">
                        <b-form-input
                          v-model.trim="people.nameLast"
                          placeholder="Last name ..."
                          :state="validateState('nameLast')"
                          aria-describedby="feedback"
                          @keyup="createUsername"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Last name is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                        <div
                          v-if="validateState('nameLast') && people.nameLast.length === 1"
                          class="font-weight-bold"
                        >
                          Note: Please ensure you only intended to have a single character as your last name
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col lg="4">
                    <label class="thick">Please enter your preferred ARM Username<span class="arm-required">*</span></label>
                  </b-col>
                  <b-col lg="4">
                    <b-form-input
                        v-model.trim="people.userName"
                        :state="validateUsernameMsg"
                        placeholder="Please enter your username"
                        aria-describedby="input-live-help username-feedback feedback"
                        @blur="validateUserNameForInput"
                    />
                    <b-form-invalid-feedback
                        id="username-feedback"
                        class="thick"
                    >
                      {{usernameErrorMsg}}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                        id="feedback"
                        class="thick"
                    >
                      Username is required and must meet the following requirements:
                      <ul>
                        <li>Must consist of lowercase alphanumeric characters</li>
                        <li>Must include at least one letter</li>
                        <li>Must be between 3 and 61 characters long</li>
                      </ul>
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick">Home institution name (unabbreviated)<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="8">
                        <VueAutosuggest
                            v-model="affiliation.name"
                            :suggestions="suggestions"
                            :inputProps="inputProps"
                            :getSuggestionValue="getSuggestionValue"
                            @input="fetchAffiliationOptions"
                            @selected="onSelected"
                        >
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">
                                  {{ suggestion.item.name }}, {{ suggestion.item.country }}
                                </span>
                            </template>
                        </VueAutosuggest>
                        <b-form-invalid-feedback
                          :state="validateState('affiliation')"
                          id="feedback"
                          class="thick"
                        >
                          Institution name is required and must not include invalid characters such as
                          {{ restrictedInstitutionsCharacters }}
                        </b-form-invalid-feedback>

                        <div
                            v-if="people.rorId"
                            class="institution-info p-2 mt-2"
                        >
                            ROR ID: {{ affiliation.id }}
                            <br />
                            Country: {{ affiliation.country }}
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick">Please choose the type of information you would like to receive: <span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="8">
                        <b-alert show>
                          <b-form-group>
                            <b-form-checkbox v-model="newsletter" value="arm" @change="selectionUpdated"><strong>Atmospheric Observer</strong> - ARM’s user facility monthly email newsletter. <i>The Atmospheric Observer</i> contains science news and user features, data and event announcements, ARM proposal calls, and research highlights.</b-form-checkbox>
                            <b-form-checkbox v-model="newsletter" value="asr" class="mt-2" :disabled="isBtnDisabled" @change="selectionUpdated"><strong>ASR News</strong> - ASR’s monthly email newsletter. <i>ASR News</i> contains news and features about the people and science of the Atmospheric System Research program, funding opportunities, and other important information for the atmospheric science community.</b-form-checkbox>
                            <label class="ml-4 mt-3"><strong>ASR Working Group Information</strong></label>
                            <p class="ml-4">
                              Connect with other research teams working on the various cloud, aerosol, land, and precipitation processes and process interactions. Working group members will also receive the <i>ASR News</i> email newsletter.
                            </p>
                            <b-form-checkbox-group v-model="workingGroupSelected" class="ml-4" @change="selectionUpdated">
                              <b-form-checkbox v-for="item in workingGroups" :key="item.value" :value="item.value" class="mt-2"><strong>{{ item.text }}</strong> - {{item.description}}</b-form-checkbox>
                            </b-form-checkbox-group>
                            <b-form-checkbox v-model="unsubscribed" @change="selectionUpdated" class="mt-2"><strong>Opt Out</strong> - I do not wish to receive newsletters or other ARM/ASR communications, including funding opportunities, proposal calls, and data and event announcements.</b-form-checkbox>
                            <b-form-invalid-feedback
                                v-if="selectionUpdated"
                                :state="validateNewsletter()"
                                class="thick"
                            >
                              Subscription is required
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-alert>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col class="offset-4" lg="8">
                        <b-alert variant="warning" :show="true">
                            <VueRecaptcha
                              :sitekey="getRecaptchaKey"
                              :loadRecaptchaScript="true"
                              @verify="verify"
                              @expired="expired"
                              @error="recaptchaError"
                              @render="render"
                            />
                            <MathCaptcha
                              v-if="!isRecaptchaLoaded"
                              @mathCaptchaValid="mathCaptchaResponse = $event"
                            />
                        </b-alert>
                    </b-col>
                </b-row>
                <b-row
                  class="mt-4"
                  v-if="!newsletterSubmitted"
                >
                    <b-col
                      class="offset-4"
                      lg="8"
                    >
                      <b-button
                        block
                        variant="primary"
                        :disabled="!requiredFields"
                        @click="createNewsletterAccount"
                      >
                        <font-awesome-icon icon="check" /> Submit
                      </b-button>
                    </b-col>
                </b-row>
                <b-row class="mt-4" v-else>
                    <b-alert :show="updateSuccess" variant="success" class="offset-4">
                        <h3><font-awesome-icon icon="check" /> Please confirm your email address!</h3>
                        <hr>
                        <p>We have sent an email to confirm your email address. To complete the registration process, please click the confirmation link. <br><br>
                            If you do not receive the confirmation email within a few minutes of signing up, please check your Spam folder just in case the email got delivered there instead of your inbox. If you need assistance, please contact us at <a href="mailto:accounts@arm.gov">accounts@arm.gov</a>.
                        </p>
                    </b-alert>
                    <b-alert :show="!updateSuccess" variant="danger" class="offset-4">
                        <h3>Error!</h3>
                        Sorry, an error occurred while saving your information. Please contact adc@arm.gov.
                    </b-alert>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    import ValidationSection from "@/components/ValidationSection"
    import { mapState } from "vuex"
    import { validationMixin } from "vuelidate";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import VueRecaptcha from "vue-recaptcha"
    import MathCaptcha from "./MathCaptcha.vue"
    import axios from "axios";
    import { VueAutosuggest } from 'vue-autosuggest';
    import Config from "../config";

    const oneOrMoreValidator = Config.validations.oneOrMoreValidator
    const usernameValidator = Config.validations.usernameValidator
    const institutionsValidator = Config.validations.institutionsValidator

    export default {
        name: "Newsletter",
        mixins: [validationMixin],
        components: {
            ValidationSection,
            VueAutosuggest,
            VueRecaptcha,
            MathCaptcha,
        },
        props: {
            email: {default: null, type: String},
        },
        data() {
            return {
                affiliation: {
                    id: null,
                    name: null,
                    country: null,
                },
                people: {
                    nameLast: "",
                    nameFirst: "",
                    affiliation: "",
                    email: "",
                    userName: ""
                },
                suggestions: [],
                verified: false,
                isRecaptchaLoaded: false,
                captchaResponse: "",
                mathCaptchaResponse: false,
                newsletter: [],
                updateSuccess: false,
                newsletterSubmitted: false,
                workingGroupSelected: [],
                isUsernameUpdated: false,
                usernameIsValid: false,
                unsubscribed: "false",
                selectionChanged: false,
                sectionValid: false
            }
        },
        computed: {
            ...mapState("app",["instOptions", "isEnvDev", "workingGroups"]),
            ...mapState("profile",["usernameErrorMsg"]),
            restrictedCharacters() {
              return Config.restrictedCharacters
            },
            restrictedUsernameCharacters() {
              return Config.restrictedUsernameCharacters
            },
            restrictedInstitutionsCharacters() {
              return Config.restrictedInstitutionsCharacters
            },
            inputProps() {
                return {
                    id: "autosuggest__input",
                    placeholder: "Home Institution Name...",
                    class: "form-control" // Provides Bootstrap Styling to input
                }
            },
            getRecaptchaKey () {
                return Config.recaptchaSiteKey
            },
            requiredFields () {
              return !this.$v.$invalid && (this.verified || this.mathCaptchaResponse) && this.validateUsernameMsg
            },
            isBtnDisabled() {
                return this.workingGroupSelected && this.workingGroupSelected.length > 0 ? true : false
            },
            validationObject() {
              return JSON.stringify(this.$v)
            },
            validateUsernameMsg() {
              if (this.isUsernameUpdated) {
                if (this.usernameErrorMsg || !this.people.userName)
                  return false
                else {
                  return this.people.userName.length < 2 ?
                      false :
                      this.validateState("userName")
                }
              } else {
                return null
              }
            },
        },
        watch: {
            workingGroupSelected(val) {
                this.workingGroupSelected = val
                this.updateNewsletterVals()
            },
            "unsubscribed": {
              handler(newVal) {
                if (newVal === true) {
                  this.newsletter = []
                  this.workingGroupSelected = []
                }
              }
            },
            "newsletter": {
              handler(newVal, oldVal) {
                if ((newVal?.includes("asr") || newVal?.includes("arm")) && this.unsubscribed === true) {
                  this.unsubscribed = false
                } else if (!(newVal?.includes("asr") || newVal?.includes("arm"))) {
                  this.unsubscribed = true
                }
              },
              deep: true
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (key === "people") {
                    let newPeopleObject = a[key].$model;
                    let oldPeopleObject = b[key].$model;
                    console.log(newPeopleObject)
                    Object.keys(newPeopleObject).forEach(key => {
                      if (this.$v.people[key] && newPeopleObject[key] !== oldPeopleObject[key]) {
                        this.$v.people[key].$touch()
                      }
                    })
                  } else {
                    if (a[key].$model !== b[key].$model) {
                      this.$v[key].$touch()
                    }
                  }
                })
              },
              deep: true,
            },
        },
        validations: {
          people: {
            nameFirst: {
              required,
              oneOrMoreValidator,
            },
            nameLast: {
              required,
              oneOrMoreValidator,
            },
            userName: {
              required,
              usernameValidator,
            },
            affiliation: {
              required: requiredIf(function() {
                return this.people?.rorId ? false : true
              }),
              institutionsValidator,
            },
            rorId: {
              required: requiredIf(function() {
                return this.people?.affiliation ? false : true
              }),
              institutionsValidator,
            }
          },
          newsletter: {
            required: requiredIf(function() {
              return !this.unsubscribed || this.unsubscribed === "false"
            })
          },
        },
        methods: {
          async validateUsername() {
            this.usernameIsValid = false
            let counter = 0
            this.isUsernameUpdated = true
            while (!this.usernameIsValid && counter < this.people.nameFirst.length) {
              let currUsername = this.people.nameFirst.substring(0 , counter + 1).concat(this.people.nameLast).toLowerCase()
              let payload = {
                username: currUsername,
                email: this.email
              }
              const result = await this.$store.dispatch("profile/validateUsername", payload)
              if (result === "") {
                this.usernameIsValid = true
              }
              this.people.userName = currUsername
              counter++
            }
          },
          validateUserNameForInput() {
            this.isUsernameUpdated = true
            if (this.people.userName) {
              let payload = {
                username: this.people.userName,
                email: this.email
              }
              this.$store.dispatch("profile/validateUsername", payload)
            }
          },
          selectionUpdated() {
            if (!this.selectionChanged) {
              this.selectionChanged = true
            }
          },
            createUsername() {
              this.validateUsername()
              this.validateState("userName")
              this.$v.people.userName.$touch()
            },
            fetchAffiliationOptions(filter) {
                if (filter) {
                    this.$store.dispatch("app/searchAffiliationOptions", filter.toLowerCase()).then(response => {
                        this.suggestions = [{
                            data: response?.map(datum => {
                                const prefix = Config.identifiers.ror_id_prefix
                                const uri = datum?.id
                                const id = uri.replace(prefix, "")
                                return {
                                    id,
                                    name: datum?.name,
                                    country: datum?.country?.country_name,
                                }
                            })
                        }]
                    })

                    this.affiliation = {
                        id: filter,
                        name: filter,
                    }
                    this.people.affiliation = filter
                } else {
                  this.affiliation = {
                    id: null,
                    name: null,
                  }
                  this.people.affiliation = null
                  this.people.rorId = null
                }
            },
            validateNewsletter() {
            var {$dirty, $error} = this.$v.newsletter;
            return $dirty ? !$error : null;
            },
            validateState(name) {
              var { $dirty, $error } = this.$v.people[name];
              return $dirty ? !$error : null;
            },
            updateNewsletterVals() {
                if(this.workingGroupSelected.length > 0) {
                    if(!this.newsletter.includes("asr")) {
                        this.newsletter.push("asr")
                    }
                }
            },
            getSuggestionValue(suggestion) {
                return suggestion.item
            },
            onSelected(text) {
              this.affiliation = text.item
              this.people.affiliation = text.item.name
              this.people.rorId = text.item.id
            },
            verify(response) {
                this.captchaResponse = response
                this.verified = true
            },
            expired() {
                this.verified = false
            },
            recaptchaError(){
                console.log("Couldn't load recaptcha")
            },
            render() {
                this.isRecaptchaLoaded = true
            },
            createNewsletterAccount() {
                if (this.requiredFields && this.email) {
                    this.people.email = this.email
                    //armAsrNewsletter
                    var payload = {
                        people: this.people,
                        captchaResponse: this.captchaResponse,
                        mathCaptchaResponse: this.mathCaptchaResponse,
                        newsletterTypes: this.newsletter,
                        workingGroups: this.workingGroupSelected
                    }
                    axios({
                        method: "post",
                        url: `${Config.api.url}/users/armAsrNewsletter`,
                        data: payload,
                        config: { headers: {"Content-Type": "multipart/form-data" }}
                    }).then((response) => {
                        this.newsletterSubmitted = true
                        if(response.data.errorMessage !== null && response.data.errorMessage.trim().length > 0) {
                            this.updateSuccess = false
                        } else {
                            this.updateSuccess = true
                        }
                    }).catch((error) => {
                        this.newsletterSubmitted = true
                        console.log(error)
                        if(error.message !== null && error.message.trim().length > 0) {
                            this.updateSuccess = false
                        }
                    })
                }

            }
        }
    }
</script>

<style scoped>
.institution-info {
    color: white;
    background-color: #5180A8;
    font-weight: bold;
    border-radius: 4px;
}
</style>
