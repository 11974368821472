<template>
  <b-modal
    id="field-invalid-modal"
    centered
    no-fade
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <div class="header title">
        Notice: Our account requirements have changed
      </div>
    </template>
    The requirements for ARM accounts have changed since your profile was last updated.
    One or more fields in your account contain values that are no longer considered valid.
    You must update these fields to conform to our current requirements.
    <br />
    <br />
    <b>Please review the form and correct any fields that are marked as invalid.</b>
    <template #modal-footer>
      <b-btn @click="$emit('hide')">
        Acknowledge
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
}
</script>

<style scoped>
.header {
  font-size: 1.2em;
}

.title {
  font-family: Oswald, Tahoma, sans-serif;
  text-transform: uppercase;
  font-weight: 700 !important;
}
</style>
