<script>
export default {
  data() {
    return {
      max: false,
    }
  },
}
</script>

<template>
  <div>
    <b-row class="mt-3"/>
      <h3>Why providing your demographic information is important to the DOE Office of Science (SC):</h3>
      <p>
        By providing your demographic information, you are assisting with SC's continued commitment to advancing diversity,
        equity, and inclusion in its business practices.
        For information you wish not to disclose, please select, "Prefer not to answer."
      </p>
      <p>
        Your individual demographic information will not be shared outside of ARM and DOE and the information in your
        ARM profile is protected by the requirements established in the Federal Privacy Act of 1974.
        <b-link
          v-if="!max"
          @click="max = true"
        >
          ... Show more
        </b-link>
        <span v-if="max">
          Aggregate anonymized demographic information may be shared with confidential review committees who are charged
          to evaluate the quality and efficacy of ARM. For example, summary statistics of all ARM users may be reviewed
          by the ARM facility triennial review panel.
          <b-link @click="max = false">
            Hide text
          </b-link>
        </span>
      </p>
  </div>
</template>

<style scoped>
  h3 {
    font-size: 1em !important;
  }
</style>
