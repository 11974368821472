import axios from "axios";
import Config from "../../config";
import router from "../../router"

const profile = {
    namespaced: true,
    state: {
        user: {},
        emailErrorMsg: null,
        usernameErrorMsg: null,
        subscriptionDetails: {},
        verficationCode: "",
        isDeveloper: null,
    },
    mutations: {
        updateUserProfile(state, response) {
            if (response) {
                state.user = response
            }
        }
    },
    actions: {
        getUserProfile({commit}, payload) {
            // console.log("<====getUserProfile===>")
            return new Promise((resolve, reject) => {
                axios({
                    method: "post",
                    url: `${Config.auth.url}/armuserreg-api/account/user`,
                    data: payload,
                }).then((response) => {
                    commit("updateUserProfile", response.data)
                    resolve(response)
                }).catch(error => {
                    console.log("Error", error)
                    if (error.response.data === "Invalid session, no ticket provided, no username/password provided" || error.response.status === 401 || error.response.status === 403) {
                        router.push({name: "login"})
                    }
                    reject(error)
                })
            })
        },
        getUserProfileByStatus({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${Config.api.url}/users/peopleByStatus`, {
                    params: payload
                }).then((response) => {
                    // console.log(response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        validateEmail({commit, state}, id) {
            state.emailErrorMsg = null
            // console.log("<====validateEmail===>")
            return new Promise((resolve, reject) => {
                axios.get(`${Config.api.url}/users/validate/email/${id}`).then((response) => {
                    // console.log(response.data)
                    if (response.data) {
                        resolve(response.data)
                    }
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        validateUsername({commit, state}, payload) {
            state.usernameErrorMsg = null
            // console.log("<====validateUsername===>")
            return axios.get(`${Config.api.url}/users/validate/username/`, {
                params: payload
            }).then((response) => {
                if (response.data && response.data != null && response.data.trim().length > 0) {
                    state.usernameErrorMsg = response.data
                }
                return response.data
            }).catch(error => {
                console.log("Error", error)
                throw error
            })
        },
        getSubscriptionDetails({commit, state}, id) {
            state.usernameErrorMsg = null
            // console.log("<====getSubscriptionDetails===>")
            axios.get(`${Config.api.url}/users/subscribe/${id}`).then((response) => {
                if (response.data) {
                    state.subscriptionDetails = response.data
                }
            }).catch(error => {
                console.log("Error", error)
            })
        },
        validateUserPermissions({state}) {
            state.isDeveloper = null
            axios.get(`${Config.auth.url}/armuserreg-api/account/permissions`).then((response) => {
                state.isDeveloper = response.data
            }).catch(error => {
                if (error.response.data === "Invalid session, no ticket provided, no username/password provided" || error.response.status === 401 || error.response.status === 403) {
                    router.push({name: "login"})
                }
                state.isDeveloper = null
                console.log("Error", error)
            })
        },
        updateVerificationCode({state}, code) {
            state.verficationCode = code
        }
    }
}

export default profile
