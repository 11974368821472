import {helpers} from "vuelidate/lib/validators";

const config = {
    app: {
        prefix: "armuserreg",
        host: "https://armweb-dev.ornl.gov/armuserreg"
    },
    api: {
        url: "https://armweb-dev.ornl.gov/armuserreg-api"
    },
    auth: {
        url: "https://armweb-dev.ornl.gov/Auth"
    },
    archiveServices: {
        url: "https://armweb-dev.ornl.gov/ArchiveServices"
    },
    elastic: {
        url: "https://armweb-dev.ornl.gov/elastic",
    },
    identifiers: {
        crossref_id_prefix: "http://dx.doi.org/10.13039/",
        ror_id_prefix: "https://ror.org/",
    },
    recaptchaSiteKey: "6LdzDTYUAAAAAL05blc17MfL5erA19qPaRfKngtH",
    validations: {
        zeroOrMoreValidator: helpers.regex("zeroOrMore", /^[^+=<>`'\"\\\/#^{};]*$/),
        nameValidator: helpers.regex("name", /^[^+=<>`'\"\\\/#^{};]+$/),
        zeroOrMoreSlashValidator: helpers.regex("zeroOrMore", /^[^+=<>`'\"\\#^{};]*$/),
        fundersValidator: helpers.regex("funders", /^[^+=<>`\"\\#^{};]+$/),
        oneOrMoreValidator: helpers.regex("oneOrMore", /^[^+=<>`'\"\\\/#^{};]+$/),
        emailValidator: helpers.regex("email", /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/),
        stateValidator: helpers.regex("state", /^[A-Z]{0,2}$/),
        usernameValidator: helpers.regex("username", /^(?=.{3,61}$)[a-z0-9]*[a-z]+[a-z0-9]*$/),
        institutionsValidator: helpers.regex("institutions", /^[^+=<>`\"\\#^{};]+$/),
        projectTitleValidator: helpers.regex("projectTitle", /^(?=.{4,250})[a-zA-Z0-9 ()-:'\",.\/]*[a-z]+[a-zA-Z0-9 ()-:'\",.\/]*$/),
    },
    orcid: {
        // authenticateUrl: "https://sandbox.orcid.org/oauth/authorize",
        authenticateUrl: "https://orcid.org/oauth/authorize",
        // Switch to localhost for local development
        //  redirectUri: "https://localhost.ornl.gov:8080/armuserreg/#/new?",
        redirectUri: "https://armweb-dev.ornl.gov/armuserreg/#/new?",
        // url: "https://sandbox.orcid.org/",
        url: "https://orcid.org/",
        // publicApiUrl: "https://pub.sandbox.orcid.org/v3.0/",
        publicApiUrl: "https://pub.orcid.org/v3.0/",
        // clientId: "APP-FDCFQU248WMBM3U1",
        clientId: "APP-6X0JJQLQRFCIONX3",
        responseType: "token",
        scope: "openid",
        nonce: "whatever",
        userInfoCookie: "user_info_arm_user_registration",
        accessTokenCookie: "orcid_access_token_arm_user_registration",
        mailingListTokenCookie: "mailing_list_token_arm_user_registration",
    },
    allowedCharacters: "! @ $ % & * ( ) - _ [ ] : , . ? |",
    restrictedCharacters: "+ = < > ` ' \" \\ / # ^ { } ;",
    restrictedUsernameCharacters: "@ + = < > ` ' \" \\ / # ^ { } ;",
    restrictedFundersCharacters: "+ = < > ` \" \\ # ^ { } ;",
    restrictedSlashCharacters: "+ = < > ` ' \" \\ # ^ { } ;",
    restrictedInstitutionsCharacters: "+ = < > ` \" \\ # ^ { } ;",
}

export default config