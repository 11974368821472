<template>
    <b-row class="mt-4">
        <b-col lg="3">
            <label :class="{thick:showRequired==true}">{{label}} <span class="arm-required" v-if="showRequired">*</span>
            </label>
        </b-col>
        <b-col lg="6">
            <b-form-input
                    v-model="inputVal"
                    :type="type"
                    :required="required"
                    :state="state"
                    @input="inputUpdated"
                    :disabled="isDisabled"
            ></b-form-input>
            <b-form-text v-if="helpText">{{helpText}}</b-form-text>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "FormInput",
        props: {
            label:      { default: "", type: String },
            name:      { default: "", type: String },
            value:      { default: "", type: String },
            required:   { default: false, type: Boolean},
            type:      { default: "text", type: String },
            state:      { default: null, type: Boolean },
            showRequired:  { default: false, type: Boolean },
            helpText:      { default: "", type: String },
            isDisabled: {default: false, type: Boolean}
        },
        data() {
            return {
                inputVal: "",
            }
        },
        created() {
            this.updateValue()
        },
        watch: {
            value:  "updateValue"
        },
        methods: {
            updateValue() {
                this.inputVal = this.value
            },
            inputUpdated (val) {
                this.$emit('inputUpdated',{
                    label: this.name,
                    value: val
                })
            }
        }
    }
</script>

<style scoped>

</style>
