import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./vuex/store"
import BootstrapVue from "bootstrap-vue"
import "vue-material/dist/vue-material.min.css"
import "vue-material/dist/theme/default.css"
import VueMoment from "vue-moment"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons"
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

// Vue Material Datepicker
import { MdDatepicker, MdButton, MdDialog } from "vue-material/dist/components"
Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdDatepicker)

Vue.config.productionTip = false
Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl", "xxxxl"]
})
Vue.use(VueMoment)

library.add(faCheck)
library.add(faSyncAlt)
library.add(faExclamationCircle)
Vue.component("font-awesome-icon", FontAwesomeIcon)
dom.watch()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
