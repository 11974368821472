<template>
    <b-container fluid>
        <TagHeader :title="title" :buttons="buttons"></TagHeader>
        <SubscriptionContent :type="type" />
    </b-container>
</template>

<script>

    import TagHeader from "@/components/TagHeader"
    import SubscriptionContent from "@/components/SubscriptionContent"

    export default {
        name: "NewsletterAccountUpdate",
        components: {
            TagHeader,
            SubscriptionContent
        },
        data() {
            return {
                title: "Subscriptions",
                type: "limitedNewsUpdate",
                buttons: [
                    {name:"back", label:"Back"},
                ]
            }
        }
    }
</script>

<style scoped>

</style>
