<template>
  <b-container v-if="isDeveloper" fluid>
    <TagHeader title="Resend Activation Email"></TagHeader>
    <div class="section1">
      <div class="section bordered">
      <div class="content" data-section-name="activation-email">
        <p>Please enter the username associated with ARM.</p>
        <b-form @submit="onSubmit">
          <FormInput id="username" label="Username" :value="username" name="username" :required=required :help-text="`**Will only send the activation email to the user, if the account status is 'U'.`" @inputUpdated="updateInput"/>
          <b-row class="mt-3">
            <b-col offset-lg="3" lg="6"><b-button type="submit" block variant="success"><font-awesome-icon icon="check" /> Submit</b-button></b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <b-row class="mt-4">
      <b-col lg="10">
        <b-alert :show="responseMessage" variant="info thick">{{responseMessage}}</b-alert>
      </b-col>
    </b-row>
  </div>
  </b-container>
</template>

<script>
import FormInput from "@/components/FormInput"
import TagHeader from "@/components/TagHeader"
import { mapState } from "vuex"
import axios from "axios";
import Config from "@/config";
export default {
  name: "ResendActivationEmail",
  components: {
    FormInput,
    TagHeader
  },
  data() {
    return {
      username: "",
      responseMessage: null,
      required: true
    }
  },
  computed: {
    ...mapState("profile",["isDeveloper"]),
  },
  mounted() {
    this.$store.dispatch("profile/validateUserPermissions")
  },
  methods: {
    onSubmit(evt) {
      this.responseMessage = null
      evt.preventDefault()
      var self = this
      axios({
        method: "post",
        url: `${Config.auth.url}/armuserreg-api/account/activation/email`,
        data: {
          username: this.username
        },
      }).then((response) => {
        self.responseMessage = response.data
      }).catch((error) => {
        self.responseMessage = error.response.data
      })
    },
    updateInput(payload) {
      this[payload.label] = payload.value
    }
  }
}
</script>

<style scoped>
.btn {
  background-color: #00BD70 !important;
}
</style>
