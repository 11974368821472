<template>
  <a
    id="orcid-link"
    :href="url"
    target="_blank"
  >
    <img
      alt="ORCID logo"
      src="../assets/ORCIDiD_iconvector.svg"
      width="24"
      height="24"
    />
    {{ url }}
  </a>
</template>

<script>
import Config from "../config"

export default {
  props: {
    orcid: {required: false, default: ""},
  },
  computed: {
    url() {
      return `${Config.orcid.url}${this.orcid}`
    }
  }
}
</script>

<style scoped>
</style>
