import Vue from "vue"
import Router from "vue-router"
import VueRouter from "vue-router"
import Login from "./views/Login"
import Dashboard from "./views/Dashboard"
import Profile from "./views/Profile"
import UpdateAccountDetails from "./views/UpdateAccountDetails"
import Subscriptions from "./views/Subscriptions"
import ForgotPassword from "./views/ForgotPassword"
import ActivateAccount from "./views/ActivateAccount"
import NewsletterAccountUpdate from "./views/NewsletterAccountUpdate"
import ResetPassword from "./views/ResetPassword"
import ResendActivationEmail from "./views/ResendActivationEmail"
import store from "./vuex/store"

Vue.use(Router)

const router = new VueRouter({
    routes: [
        {
            path: "/",
            name: "login",
            component: Login,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/",
            name: "logout",
            component: Login,
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/new",
            name: "profile",
            component: Profile,
        },
        {
            path: "/edit/:id",
            name: "edit",
            component: Profile,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/account/:id",
            name: "updateAccountDetails",
            component: UpdateAccountDetails,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/update/:id",
            name: "subscribe",
            component: Subscriptions,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/newsletter/:id",
            name: "newsletter",
            component: NewsletterAccountUpdate,
        },
        {
            path: "/forgot",
            name: "forgot",
            component: ForgotPassword,
        },
        {
            path: "/activate/:params*",
            name: "activate",
            component: ActivateAccount,
        },
        {
            path: "/password/:params*",
            name: "resetPassword",
            component: ResetPassword,
        },
        {
            path: "/email",
            name: "email",
            component: ResendActivationEmail,
        },
    ]
})

router.beforeEach((to, from, next) => {
    //load user details from local storage
    store.commit("auth/init")
    // next()

    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["auth/isAuthenticated"]) {
            next()
            return
        }
        localStorage.setItem("newRoute", to.path)
        next('/')
    } else {
        next()
    }
})

export default router
