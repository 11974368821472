<template>
    <div class="section1">
        <div class="section bordered">
            <h2 v-if="isNew">Become an ARM Facility User</h2>
            <p>
                To register with the Atmospheric Radiation Measurement (ARM) user
                facility, please complete the following form. This information is
                required for documenting the value and usage of the ARM facility
                by the research community. As a U.S. Department
                of Energy (DOE) scientific user facility, ARM welcomes users from
                all institutions and nations.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderInfoSection",
        props: {
            isNew: {default: true, type: Boolean},
        },
    }
</script>

<style scoped>

</style>
