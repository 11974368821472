<template>
    <div>
        <b-alert
          :show="showAlert"
          variant="danger"
          dismissible
        >
            Sorry, an error occurred while retrieving your information. Please contact adc@arm.gov.
        </b-alert>
        <HeaderInfoSection :isNew="!isEdit" />
        <ValidationSection />
        <UserSectionTemplate
          :orcidInfo="orcidInfo"
          :people="people"
          :isRecordSubmitted.sync="isRecordSubmitted"
          :isEdit="isEdit"
          @fieldUpdate="updateField"
          @userSectionValid="userSectionValid = $event"
          @persistUserInfo="persistUserInfo"
        />
        <InstitutionalTemplate
          :people="people"
          :isRecordSubmitted="isRecordSubmitted"
          @fieldUpdate="updateField"
          @institutionValid="institutionValid = $event"
        />
        <UserTypeTemplate
          :people="people"
          :isRecordSubmitted="isRecordSubmitted"
          @fieldUpdate="updateField"
          @userTypeValid="userTypeValid = $event"
        />
        <ProjectsTemplate
          v-if="people.userTypeCode === 'R'"
          :isRecordSubmitted="isRecordSubmitted"
          :project="project"
          @projectValid="projectValid = $event"
        />
        <SubscriptionContent
          v-if="!isEdit"
          :loadValues="loadValues"
          :newsletterVals="newsletter"
          :workingGroupsVals="workingGroups"
          @subscriptionSectionValid="subscriptionSectionValid = $event"
          @newsletterUpdate="newsletterUpdate"
        />
        <SignatureTemplate
          :isRecordSubmitted="isRecordSubmitted"
          :hideSubmit="hideSubmit"
          :sectionsValid="sectionsValid"
          :updateSuccess="updateSuccess"
          :previousUserName="previousUserName"
          :email="emailValue"
          :people="people"
          :isEdit="isEdit"
          @onSubmit="onSubmit"
          @submitUpdate="submitUpdate"
          @updateParent="updateValue"
          @signatureSectionValid="signatureSectionValid = $event"
        />
        <OrcidModal
          :orcidInfo="orcidInfo"
          @overwrite="overwrite"
        />
        <FieldInvalidModal @hide="$bvModal.hide('field-invalid-modal')" />
      <OrcidPromptModal />
    </div>
</template>

<script>
    import UserSectionTemplate from "@/components/UserSectionTemplate"
    import InstitutionalTemplate from "@/components/InstitutionalTemplate"
    import UserTypeTemplate from "@/components/UserTypeTemplate"
    import ProjectsTemplate from "@/components/ProjectsTemplate"
    import SignatureTemplate from "@/components/SignatureTemplate"
    import HeaderInfoSection from "@/components/HeaderInfoSection"
    import SubscriptionContent from "@/components/SubscriptionContent"
    import ValidationSection from "@/components/ValidationSection"
    import OrcidModal from "@/components/OrcidModal"
    import OrcidPromptModal from "@/components/OrcidPromptModal"
    import FieldInvalidModal from "@/components/FieldInvalidModal"
    import { mapState } from "vuex"
    import Config from "../config"
    import _ from "underscore";
    import axios from "axios";

    export default {
        name: "AccountProfileContent",
        components: {
          UserSectionTemplate,
          InstitutionalTemplate,
          UserTypeTemplate,
          ProjectsTemplate,
          SignatureTemplate,
          HeaderInfoSection,
          SubscriptionContent,
          ValidationSection,
          OrcidModal,
          OrcidPromptModal,
          FieldInvalidModal,
        },
        props: {
            email: {default: null, type: String}
        },
        data() {
            return {
                stateName: "",
                orcidInfo: {},
                paramsIdBeforeRedirect: "",
                people: {
                    personId: null,
                    nameLast: "",
                    nameFirst: "",
                    nameMid: "",
                    affiliation: null,
                    address1: "",
                    address2: "",
                    city: null,
                    state: "0",
                    zipcode: "",
                    countryCode: null,
                    genderCode: null,
                    pronounId: null,
                    pronounOther: null,
                    ethnicityCode: null,
                    races: [],
                    disabilities: [],
                    phone: null,
                    email: "",
                    lastUpdateTime: null,
                    userName: null,
                    statusCode: null,
                    lastLoginTime: null,
                    validateTime: null,
                    creationTime: null,
                    employmentTypeCode: null,
                    userTypeCode: "R",
                    archUserId: null,
                    orcid: null,
                    surveyResults: null,
                    accessStatement: "",
                    citizenCountryCode: null,
                    isSmallBusiness: null,
                    employmentTypeConfirm: null,
                    orcidValidation: null,
                    rorId: null,
                },
                project: {
                    projectTitle: "",
                    estStartDate: null,
                    estEndDate: null,
                    researchStatement: "",
                    projectTypeCode: null,
                    fundingSource: null,
                    userTypeConfirm: null,
                    crossrefId: null,
                },
                isRecordSubmitted: null,
                peopleRequiredFields: ["nameLast", "nameFirst", "affiliation", "address1", "city", "state", "phone", "email", "userName", "countryCode", "genderCode", "ethnicityCode", "races", "disabilities", "employmentTypeCode", "userTypeCode", "citizenCountryCode", "isSmallBusiness"],
                projectRequiredFields: ["projectTitle", "researchStatement", "projectTypeCode", "fundingSource"],
                password: "",
                previousUserName: null,
                isEdit: false,
                updateSuccess: null,
                hideSubmit: false,
                validPasswords: null,
                newsletter: [],
                workingGroups: [],
                loadValues: false,
                showAlert: false,
                userSectionValid: false,
                signatureSectionValid: false,
                subscriptionSectionValid: false,
                institutionValid: false,
                userTypeValid: true,
                projectValid: false,
            }
        },
        computed: {
            ...mapState("profile",["user", "emailErrorMsg", "usernameErrorMsg", "verficationCode"]),
            ...mapState("auth",["details"]),
            ...mapState("app",["states"]),
            emailValue() {
              if (this.email) {
                return this.email
              } else if (this.people?.email && this.isEdit) {
                return this.people.email
              } else {
                return null
              }
            },
            sectionsValid() {
              if (this.isEdit) {
                return this.people.userTypeCode === "R" ?
                    this.userSectionValid && this.institutionValid && this.userTypeValid && this.projectValid && this.signatureSectionValid :
                    this.userSectionValid && this.institutionValid && this.userTypeValid && this.signatureSectionValid
              } else {
                return this.people.userTypeCode === "R" ?
                    this.userSectionValid && this.institutionValid && this.userTypeValid && this.subscriptionSectionValid && this.projectValid :
                    this.userSectionValid && this.institutionValid && this.userTypeValid && this.subscriptionSectionValid
              }
            },
            orcidUnverified() {
              return this.people?.orcid && !this.people?.orcidValidation
            },
        },
        watch: {
            user:  "setDataValues",
            $route: "init",
            email: "updateEmail",
            "people.userName"(value) {
              if (value && !this.previousUserName) {
                this.previousUserName = value
              }
            },
        },
        mounted() {
          this.init()
        },
        methods: {
            async handleOrcidRedirect() {
              const accessToken = localStorage.getItem(Config.orcid.accessTokenCookie)
              if (accessToken) {
                localStorage.removeItem(Config.orcid.accessTokenCookie)
                this.getUserInfoCookie()
                const orcid = await axios({
                  method: "get",
                  url: `${Config.orcid.url}oauth/userinfo`,
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                  },
                }).then(response => response?.data?.sub)

                if (orcid) {
                  const orcidInfo = await axios({
                    method: "get",
                    url: `${Config.orcid.publicApiUrl}${orcid}/record`,
                    withCredentials: false,
                    headers: {
                      Accept: "application/vnd.orcid+json",
                    },
                  }).then(response => response.data)

                  if (orcidInfo && Object.keys(orcidInfo).length > 0) {
                    this.orcidInfo = orcidInfo
                    this.people.orcidValidation = this.$moment.utc(new Date())
                    this.people.orcid = this.orcidInfo?.["orcid-identifier"]?.path
                    this.$bvModal.hide("orcidpromptmodal-modal")
                    this.$bvModal.show("orcidmodal-modal")
                  }
                }
              }
            },
            overwrite() {
              this.stateName = this.orcidInfo?.["activities-summary"]?.["employments"]?.["affiliation-group"][0]?.["summaries"][0]?.["employment-summary"]?.["organization"]?.["address"]?.["region"]
              const returnedState = this.mappingStatesToStateCodes(this.stateName)
              const orcidSchema = {
                people: {
                  nameFirst: this.orcidInfo?.person?.name?.["given-names"]?.value,
                  nameLast: this.orcidInfo?.person?.name?.["family-name"]?.value,
                  city: this.orcidInfo?.["activities-summary"]?.["employments"]?.["affiliation-group"][0]?.["summaries"][0]?.["employment-summary"]?.["organization"]?.["address"]?.["city"],
                  state: returnedState.value,
                  affiliation: this.orcidInfo?.["activities-summary"]?.["employments"]?.["affiliation-group"][0]?.["summaries"][0]?.["employment-summary"]?.["organization"]?.["name"],
                  countryCode: this.orcidInfo?.["person"]?.["addresses"]?.["address"][0]?.["country"]?.["value"],
                  citizenCountryCode: this.orcidInfo?.["person"]?.["addresses"]?.["address"][0]?.["country"]?.["value"]
                },
              }

              Object.keys(orcidSchema).forEach(category => {
                Object.keys(orcidSchema[category]).forEach(key => {
                  if (orcidSchema[category][key]) {
                    this[category][key] = orcidSchema[category][key]
                  }
                })
              })
            },
          updateValue (value) {
            this.people.userName = value
          },
          mappingStatesToStateCodes(passedStateName) {
            let localReturnedState = {}
            this.states.forEach(state => {
              if (passedStateName === state.text) {
                localReturnedState = state
              }
            })
            return localReturnedState
          },
            persistUserInfo() {
              const userInfo = {
                people: this.people,
                project: this.project,
                newsletter: this.newsletter,
                workingGroups: this.workingGroups,
              }

              delete userInfo.people.password
              delete userInfo.people.tokenExpiration
              delete userInfo.people.verificationToken

              localStorage.setItem(Config.orcid.userInfoCookie, JSON.stringify(userInfo))
            },
            getUserInfoCookie() {
              const userInfoCookie = localStorage.getItem(Config.orcid.userInfoCookie)
              if (userInfoCookie) {
                localStorage.removeItem(Config.orcid.userInfoCookie)
                const userInfo = JSON.parse(userInfoCookie)
                this.people = userInfo.people
                this.project = userInfo.project
                this.newsletter = userInfo.newsletter
                this.workingGroups = userInfo.workingGroups
              }
            },
            newsletterUpdate(payload) {
                this.newsletter = payload.newsletter
                this.workingGroups = payload.workingGroups
            },
            updateEmail() {
                this.people.email = this.email ? this.email : ""
            },
            setDataValues() {
                if (this.user?.people) {
                  Object.keys(this.user.people).forEach(key => this.people[key] = this.user.people[key])
                }

                if (this.user?.peopleProtected) {
                  this.people.citizenCountryCode = this.user.peopleProtected.citizenshipCountryCode
                  this.people.genderCode = this.user.peopleProtected.genderCode
                  this.people.ethnicityCode = this.user.peopleProtected.ethnicityCode

                  this.people.races = this.user.peopleProtected.raceCode ?
                    JSON.parse(this.user.peopleProtected.raceCode) :
                    []

                  this.people.disabilities = this.user.peopleProtected.disabilityCode ?
                    JSON.parse(this.user.peopleProtected.disabilityCode) :
                    []
                }

                this.project = this.user.project ? this.user.project : this.project

                if (this.people.userName) {
                  this.showAlert = false
                } else {
                  this.showAlert = true
                }

              if (this.project.projectTypeCode === "U") {
                  this.project.projectTypeCode = null
                }

              const accessToken = localStorage.getItem(Config.orcid.accessTokenCookie)
              if (!this.people.orcidValidation && !accessToken) {
                  this.$bvModal.show("orcidpromptmodal-modal")
                }
            },
            handleNewFieldRequirements() {
              if (!this.sectionsValid) {
                this.$bvModal.show("field-invalid-modal")
              }
            },
            init() {
                if (this.$route.params.id) {
                    this.isEdit = true
                    if(this.details && this.details.username) {
                        var payload = {
                            username: this.$route.params.id,
                        }
                        this.hideSubmit = this.$route.params.id.toLowerCase() === this.details.username.toLowerCase() ? false : true
                        this.$store.dispatch("profile/getUserProfile" , payload).then((response) =>{
                            this.setDataValues()
                            this.handleNewFieldRequirements()
                            this.handleOrcidRedirect()
                        }).catch(error => {
                            if (error.response.data === "Invalid session and no ticket provided" || error.response.status === 403) {
                                this.$store.commit("auth/logout")
                                this.$router.push({name: "login"})
                            }
                        })
                    }else{
                        this.$router.push({name: "login"})
                    }
                } else {
                    if(this.email) {
                        if (!this.verficationCode) {
                          const token = localStorage.getItem(Config.orcid.mailingListTokenCookie)
                          if (token) {
                            localStorage.removeItem(Config.orcid.mailingListTokenCookie)
                            this.$store.dispatch("profile/updateVerificationCode", token)
                          }
                        }

                        var payload = {
                            email: this.email,
                            token: this.verficationCode,
                        }
                        this.$store.dispatch("profile/getUserProfileByStatus" , payload).then((response) =>{
                            this.updateProfileValues(response)
                            this.handleOrcidRedirect()
                        }).catch(error => {
                            this.updateProfileValues(error)
                        })
                    } else {
                        this.resetDataValues()
                        this.isEdit = false
                        this.setDefaultValues()
                    }
                }
            },
            updateProfileValues (response) {
                if (response && response.people && response.people.personId && response.personId !== null) {
                    Object.keys(response.people).forEach(key => this.people[key] = response.people[key])

                    if (response.peopleProtected) {
                      this.people.citizenCountryCode = response.peopleProtected.citizenshipCountryCode
                      this.people.genderCode = response.peopleProtected.genderCode
                      this.people.ethnicityCode = response.peopleProtected.ethnicityCode

                      this.people.races = response.peopleProtected.raceCode ?
                        JSON.parse(response.peopleProtected.raceCode) :
                        []

                      this.people.disabilities = response.peopleProtected.disabilityCode ?
                        JSON.parse(response.peopleProtected.disabilityCode) :
                        []
                    }

                    this.newsletter = response.newsletterTypes
                    this.workingGroups = response.workingGroups
                    this.loadValues = true
                } else {
                    this.resetDataValues()
                }
                this.isEdit = false
                this.setDefaultValues()
                this.updateEmail()
            },
            updateField(payload) {
                this[payload.label] = payload.value
            },
            setDefaultValues() {
                this.people.userTypeCode = "R"
            },
            onSubmit(data) {
                this.password = data.password
                this.validPasswords = data.validPasswords
                var errors = this.validateRequiredFields()
                if (errors.length > 0 || this.emailErrorMsg || this.usernameErrorMsg) {
                    data.event.preventDefault()
                    this.isRecordSubmitted = true
                    return false
                } else {
                    this.people.archUserId = this.people.nameLast.toUpperCase() + this.people.nameFirst.charAt(0).toUpperCase() + 1

                    const peopleProtected = {
                      citizenshipCountryCode: this.people.citizenCountryCode,
                      genderCode: this.people.genderCode,
                      ethnicityCode: this.people.ethnicityCode,
                      raceCode: JSON.stringify(this.people.races),
                      disabilityCode: JSON.stringify(this.people.disabilities),
                    }

                    const people = JSON.parse(JSON.stringify(this.people))
                    people.citizenCountryCode = null
                    people.genderCode = null
                    people.ethnicityCode = null
                    people.races = []
                    people.disabilities = []

                    var payload = {
                        people,
                        peopleProtected,
                        project: this.project,
                        password: this.password,
                        captchaResponse: data.captchaResponse,
                        mathCaptchaResponse: data.mathCaptchaResponse,
                        newsletterTypes: this.newsletter,
                        workingGroups: this.workingGroups
                    }
                    var self = this
                    axios({
                        method: "post",
                        url: `${Config.api.url}/users/create`,
                        data: payload,
                        config: { headers: {"Content-Type": "multipart/form-data" }}
                    }).then((response) => {
                        this.isRecordSubmitted = true
                        this.hideSubmit = true
                        if(response.data !== null && response.data.trim().length > 0) {
                            self.updateSuccess = false
                        } else {
                            self.updateSuccess = true
                        }
                    }).catch((error) => {
                        this.isRecordSubmitted = true
                        this.hideSubmit = true
                        if(error.message !== null && error.message.trim().length > 0) {
                            this.updateSuccess = false
                        }
                    })
                }
            },
            submitUpdate(ev) {
                var errors = this.validateRequiredFields()
                if (errors.length > 0 || this.emailErrorMsg || this.usernameErrorMsg) {
                    ev.preventDefault()
                    this.isRecordSubmitted = true
                    return false
                } else {
                    const peopleProtected = {
                      citizenshipCountryCode: this.people.citizenCountryCode,
                      genderCode: this.people.genderCode,
                      ethnicityCode: this.people.ethnicityCode,
                      raceCode: JSON.stringify(this.people.races),
                      disabilityCode: JSON.stringify(this.people.disabilities),
                    }

                    const people = JSON.parse(JSON.stringify(this.people))
                    people.citizenCountryCode = null
                    people.genderCode = null
                    people.ethnicityCode = null
                    people.races = []
                    people.disabilities = []

                    var payload = {
                        people,
                        peopleProtected,
                        project: this.project,
                    }

                    let shouldLogOut = this.previousUserName !== this.people?.userName

                    var self = this
                    axios({
                        method: "post",
                        url: `${Config.auth.url}/armuserreg-api/account/update`,
                        data: payload,
                        config: { headers: {"Content-Type": "multipart/form-data" }}
                    }).then((response) => {
                        this.isRecordSubmitted = true
                        this.hideSubmit = true
                        if (response.data !== null && response.data.trim().length > 0) {
                            self.updateSuccess = false
                        } else {
                            self.updateSuccess = true
                            if (shouldLogOut) {
                              this.$store.dispatch("auth/logout").then(() => {
                                this.$router.push({name: "login"})
                              })
                            }
                        }
                    }).catch((error) => {
                        this.isRecordSubmitted = true
                        this.hideSubmit = true
                        if (error.response.data === "Invalid session and no ticket provided" || error.response.status === 403) {
                            this.$store.commit("auth/logout")
                            this.$router.push({name: "login"})
                        }
                        if(error.message !== null && error.message.trim().length > 0) {
                            this.updateSuccess = false
                        }
                    })
                }
            },
            validateRequiredFields() {
                var errors = []
                _.each(this.peopleRequiredFields, field => {
                    if(field === "state") {
                        if(this.people[field] === "0")
                            errors.push(field)
                    } else if (field === "affiliation") {
                      if ((this.people[field] === null || this.people[field] === "") && this.people.rorId === null) {
                        errors.push(field)
                      }
                    } else {
                        if(this.people[field] === null || this.people[field] === "")
                            errors.push(field)
                        else {
                            if (field === "phone" && this.people[field].length > 16)
                                errors.push(field)
                            if (field === "zipcode" && this.people[field].length > 10)
                                errors.push(field)
                        }
                    }
                })
                if (this.people.userTypeCode === "R") {
                    _.each(this.projectRequiredFields, field => {
                        if (field === "fundingSource") {
                          if ((this.project[field] === null || this.project[field] === "") && this.project.crossrefId === null) {
                            errors.push(field)
                          }
                        } else if (!this.project[field]) {
                            errors.push(field)
                        }
                    })
                } else {
                    if (!this.people.accessStatement)
                        errors.push("accessStatement")
                }
                if((!this.validPasswords || !this.password) && !this.isEdit) {
                    errors.push("password")
                }

                return errors
            },
            resetDataValues() {
                //Reset people values
                this.people.personId = null
                this.people.nameLast = ""
                this.people.nameFirst = ""
                this.people.nameMid = ""
                this.people.affiliation = null
                this.people.address1 = ""
                this.people.address2 = ""
                this.people.city = null
                this.people.state = "0"
                this.people.genderCode = null
                this.people.ethnicityCode = null
                this.people.races = []
                this.people.disabilities = []
                this.people.pronounId = null
                this.people.pronounOther = null
                this.people.zipcode = ""
                this.people.countryCode = null
                this.people.phone = null
                this.people.email = ""
                this.people.lastUpdateTime = null
                this.people.userName = null
                this.people.statusCode = null
                this.people.lastLoginTime = null
                this.people.validateTime = null
                this.people.creationTime = null
                this.people.employmentTypeCode = null
                this.people.userTypeCode = "R"
                this.people.archUserId = null
                this.people.orcid = null
                this.people.surveyResults = null
                this.people.accessStatement = ""
                this.people.citizenCountryCode = null
                this.people.isSmallBusiness = null
                this.people.employmentTypeConfirm = null
                // Reset project values
                this.project.projectTitle = ""
                this.project.estStartDate = null
                this.project.estEndDate = null
                this.project.researchStatement = ""
                this.project.projectTypeCode = null
                this.project.fundingSource = null
                this.project.userTypeConfirm = null
            },
        }
    }
</script>

<style scoped>

</style>
