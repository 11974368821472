<template>
    <b-row>
        <b-col lg="6">
            <label :class="{thick:required===true}">{{label}} <span v-if="required===true" class="arm-required">*</span></label>
            <div v-if="description">
                {{description}}
            </div>
        </b-col>
        <b-col lg="6">
            <b-form-textarea
                    v-model="inputVal"
                    rows="4"
                    :state="state"
                    aria-describedby="feedback"
                    @change="textAreaUpdated"
            ></b-form-textarea>
            <b-form-invalid-feedback id="feedback" class="thick">{{message}}</b-form-invalid-feedback>
        </b-col>
    </b-row>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "FormTextArea",
        props: {
            value:      { default: "", type: String },
            label:      { default: "", type: String },
            description: { default: "", type: String },
            name:      { default: "", type: String },
            required:      { default: true, type: Boolean },
            state:      { default: null, type: Boolean },
            message:      { default: "", type: String },
        },
        data() {
            return {
                inputVal: ""
            }
        },
        created() {
            this.updateValue()
        },
        watch: {
            value:  "updateValue"
        },
        methods: {
            updateValue() {
                this.inputVal = this.value
            },
            textAreaUpdated (val) {
                this.$emit('textAreaUpdated',{
                    label: this.name,
                    value: val
                })
            }
        }
    }
</script>

<style scoped>

</style>