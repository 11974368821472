import axios from "axios";
import Config from "../../config";
import router from "../../router"

axios.defaults.withCredentials = true

const auth = {
    namespaced: true,
    state: {
        details: {
            username: null,
            firstName: null,
            lastName: null,
            validateTime: null,
            email: null,
        },
        errorMessage: null,
    },
    getters: {
        isAuthenticated: state => !!state.details.username,
    },
    mutations: {
        init (state) {
            // Check whether the current time is past the
            // access token's expiry time
            const expiresAt = JSON.parse(localStorage.getItem("expires_at"))
            if (new Date().getTime() < expiresAt) {
                state.details = JSON.parse(localStorage.getItem("userreg_login"))
            }
        },
        updateLoginDetails (state, response) {
            if (response) {
                state.details.username = response.userName
                state.details.firstName = response.nameFirst
                state.details.lastName = response.nameLast
                state.details.validateTime = response.validateTime
                state.details.email = response.email
                this.commit("auth/addLoginInfoToStorage")
                var newRoute = localStorage.getItem("newRoute")
                if (newRoute !== null) {
                    localStorage.removeItem("newRoute")
                    router.push({path: newRoute})
                } else {
                    router.push({name: "dashboard"})
                }
            } else {
                state.errorMessage = response.errorMessage
            }
        },
        addLoginInfoToStorage(state) {
            // Set the time that the login details will expire at --> set to 30 min
            const expiresAt = JSON.stringify(20*60* 1000 + new Date().getTime())
            localStorage.setItem("userreg_login", JSON.stringify(state.details))
            localStorage.setItem("expires_at", expiresAt)
        },
        logout (state) {
            state.details.username = null
            state.details.firstName = null
            state.details.lastName = null
            state.details.validateTime = null
            // state.details.isAdmin = false
            state.errorMessage = null
            this.commit("auth/addLoginInfoToStorage")
        },
        setWarningMsg(state, warningMsg) {
            state.errorMessage = warningMsg
        },


    },
    actions: {
        validateLogin({commit, state}, payload) {
            state.errorMessage = null
            return new Promise((resolve, reject) => {
                axios({
                    url: `${Config.auth.url}/login`,
                    method: "post",
                    auth: {
                        username: payload.username,
                        password: payload.password
                    }
                }).then((response) => {
                    // console.log(response)
                    resolve(response)
                    commit("updateLoginDetails", response.data)
                }).catch(error => {
                    if (error.response.status === 401) {
                        commit("setWarningMsg", "Invalid Credentials!")
                    } else if (error.response.status === 403) {
                        commit("setWarningMsg", "Invalid Session!")
                    } else {
                        commit("setWarningMsg", "")
                    }

                    reject(error)
                })
            })
        },
        updateEmail({state}, email) {
            state.details.email = email
            this.commit("auth/addLoginInfoToStorage")
        },
        logout ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${Config.auth.url}/logout`)
                    .then((response) => {
                        if (response.data) {
                            resolve(response)
                            commit("logout")
                        }
                    }).catch(error => {
                    reject(error)
                })
            })

        }

    }

}

export default auth
