<template>
    <div class="section1">
        <div class="section bordered">
            <div class="content" data-section-name="generalinfo">
                <p>You are successfully logged in as <strong>{{details.firstName}} {{details.lastName}}</strong></p>
                <p v-if="displayUpdateMessage"><b-badge variant="danger">IMPORTANT</b-badge> Due to inactivity, your account has been placed in hibernation status. Please click on <strong>'Personal Profile'</strong> below to update or confirm your ARM profile information before proceeding.</p>
                <div>
                    <b-button variant="link" class="mr-sm-2" @click="navPage('edit')">Personal Profile</b-button>
                    <b-button variant="link" class="mr-sm-2" @click="navPage('account')">Update Account Details</b-button>
                    <b-button variant="link" class="mr-sm-2" @click="navPage('update')">Subscriptions</b-button>
                    <b-button v-if="isDeveloper" variant="link" class="mr-sm-2" to="/email">Resend Activation Email</b-button>
                    <b-button variant="link" class="mr-sm-2" @click="navPage('logout')">Logout</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    export default {
        name: "DashboardContent",
        computed: {
            ...mapState("auth",["details"]),
            ...mapState("profile",["isDeveloper"]),
            displayUpdateMessage() {
              return this.details && this.details.validateTime && !this.$moment(this.details.validateTime).isAfter(this.$moment().subtract(1, 'years')) ? true : false
            }
        },
        mounted() {
          this.$store.dispatch("profile/validateUserPermissions")
        },
        methods: {
            navPage (pageName) {
                if (pageName == "logout") {
                    this.$store.dispatch("auth/logout")
                    this.$router.push({name: pageName})
                } else
                    this.$router.push({path: `/${pageName}/${this.details.username}`})
            }
        }
    }
</script>

<style scoped>
    .btn {
        background-color: #00BD70 !important;
    }
</style>
