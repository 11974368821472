<script>
export default {
  data() {
    return {
      a: null,
      b: null,
      sum: null,
      input: null,
      valid: null,
    }
  },
  created() {
    this.a = Math.ceil(Math.random() * 10)
    this.b = Math.ceil(Math.random() * 10)
    this.sum = this.a + this.b
  },
  methods: {
    validate(value) {
      let result = null
      try {
        result = value.toString() === this.sum.toString()
      } catch(error) {
        console.error(error)
      }
      this.valid = result
      this.$emit("mathCaptchaValid", this.valid)
    },
  },
}
</script>

<template>
  <div>
    <div class="font-weight-bold mb-2">
      To prove you are not a robot, please answer the following question:
    </div>
    <b-row align-v="center">
      <b-col cols="auto">
        What is {{ a }} + {{ b }}?
      </b-col>
      <b-col>
        <b-input
          v-model="input"
          :state="valid"
          step="1"
          type="number"
          @input="validate"
        />
      </b-col>
    </b-row>
    <b-form-invalid-feedback
      :state="valid"
      class="thick"
    >
      Incorrect answer. Answer must be correct to submit the form.
    </b-form-invalid-feedback>
  </div>
</template>

<style scoped>
</style>