<template>
        <div class="section1">
            <div class="section bordered">
                <div class="content" data-section-name="generalinfo">
                    <h2>Password Requirements</h2>
                    <b-row class="mt-4">
                      <b-col><h3>Passwords must:</h3></b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col lg="6">
                        <ul class="thick ml-2">
                          <li :class="validateErrorClass(tenCharRule)">{{tenCharRule.message}}</li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col><h3>And have at least 3 out of 4:</h3></b-col>
                    </b-row>
                    <b-col lg="6">
                      <ul class="thick ml-2">
                        <li v-for="rule in rules" :key="rule.message" :class="validateErrorClass(rule)">{{rule.message}}</li>
                      </ul>
                    </b-col>
                    <br />
                    <b-form @submit="onSubmit">
                      <b-row class="mt-4">
                        <b-col lg="6"><FormInput label="Current Password" :value="currentPassword" name="currentPassword" :required=required :showRequired=required @inputUpdated="updateInput" type="password"/></b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col lg="6"><FormInput label="New Password" :value="newPassword" name="newPassword" :required=required :showRequired=required @inputUpdated="updateInput" type="password" :state="isPasswordValid"/></b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col lg="6"><FormInput label="Verify Password" :value="verifyPassword" name="verifyPassword" :required=required :showRequired=required @inputUpdated="updateInput" type="password" :state="samePasswords" /></b-col>
                      </b-row>
                    <b-row class="mt-5">
                        <b-col offset-lg="3" lg="6"><b-button type="submit" block variant="primary" :disabled="!isDisabled"><font-awesome-icon icon="check" /> Submit</b-button></b-col>
                    </b-row>
                    </b-form>
                </div>
            </div>
            <b-row class="mt-4">
                <b-col lg="10">
                    <b-alert :show="showError && updateFailed" variant="danger" class="thick">Sorry! We have encountered an error. The username or password entered does not match with our records.
                    If this problem persists, please use the ARM password help page to request a reset. Thank you.
                    </b-alert>
                    <b-alert :show="showError && !updateFailed" variant="success">
                        <h3>Success!</h3>
                        Your password has been successfully updated with ARM. You may
                        continue to use the ARM Site.
                    </b-alert>
                </b-col>
            </b-row>
        </div>
</template>

<script>
    import FormInput from "@/components/FormInput"
    import _ from "underscore"
    import Config from "../config";
    import axios from "axios";
    export default {
        name: "ResetContent",
        components: {
            FormInput
        },
        data() {
            return {
                isUsernameDisabled: true,
                threeOutOfFourRulePassed: false,
                username: "",
                currentPassword: "",
                newPassword: "",
                verifyPassword: "",
                required: true,
                showError: false,
                updateFailed: false,
                rules: [
                  {message: "One upper-case character", regex:/[A-Z]+/},
                  {message: "One lower-case character", regex:/[a-z]+/},
                  {message: "One number", regex:/[0-9]+/},
                  {message: "One of the following special characters: '#?!@$%^&*-'", regex:/[#?!@$%^&*-]/},
                ],
              tenCharRule: {message: "Be at least 10 characters long", regex:/.{10,}/},
            }
        },
        computed: {
          isPasswordValid() {
            var errors = []
            _.each(this.rules, (rule) => {
              if (!rule.regex.test(this.newPassword))
                errors.push(rule.message)
            })
            this.checkThreeOutOfFourRule(errors)
            if (errors.length > 1) {
              return false
            } else {
              if (this.newPassword.length >= 10) {
                return true
              } else {
                return false
              }
            }

          },
            samePasswords () {
                return this.passwordsFilled() && (this.newPassword == this.verifyPassword) ? true : false
            },
            isDisabled() {
                return this.isPasswordValid && this.samePasswords ? true : false
            }
        },
        mounted() {
            this.init()
        },
        watch: {
            // subscriptionDetails:  "setDataValues",
            $route: "init"
        },
        methods: {
            checkThreeOutOfFourRule(errors) {
              this.threeOutOfFourRulePassed = errors.length < 2
            },
            init() {
                console.log(this.$route.params.id)
                if (this.$route.params.id) {
                    this.username = this.$route.params.id
                }
            },
            passwordsFilled () {
                return (this.newPassword !== '' && this.verifyPassword !== '')
            },
          validateErrorClass(condition) {
            if (condition.regex.test(this.newPassword)) {
              return "valid"
            } else {
              if (condition.message === "Be at least 10 characters long") {
                return "invalid"
              } else if (this.threeOutOfFourRulePassed) {
                return null
              } else {
                return "invalid"
              }
            }
          },
            updateInput(payload) {
                this[payload.label] = payload.value
            },
            onSubmit(ev) {
                ev.preventDefault()
                if (!this.samePasswords) {
                   this.errorMsg = "The New and Verify Passwords entered doesn't match."
                    return false
                } else {
                    var people = {
                        userName: this.username
                    }
                    var payload = {
                        people: people,
                        password: this.currentPassword,
                        newPassword: this.newPassword
                    }
                    var self = this
                    axios({
                        method: "post",
                        url: `${Config.auth.url}/armuserreg-api/account/update/password`,
                        headers: {
                            Authorization: `Basic ${btoa(`${this.username}:${this.currentPassword}`)}`
                        },
                        data: payload
                    }).then((response) => {
                        console.log(response)
                        if (response.data != null && response.data.trim().length > 0) {
                          self.showError = true
                            self.updateFailed = true
                        } else {
                            self.showError = true
                            self.updateFailed = false
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
