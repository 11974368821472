<template>
    <b-container fluid>
        <TagHeader :title="title"></TagHeader>
        <div class="section1">
            <div class="section bordered">
                <b-form @submit="activateAccount" v-if="showActivateField">
                <h2>Activate Your Account</h2>
                <b-row class="mt-4">
                    <b-col lg="3">
                        <label class="thick">Activation code:<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input v-model.trim="verificationCode" placeholder="Please enter your activation code" required></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col offset-lg="3" lg="6"><b-button block type="submit" variant="primary"><font-awesome-icon icon="check" /> Activate</b-button></b-col>
                </b-row>
                </b-form>
                <div v-else>
                    <div v-if="verified === true">
                        <div v-if="isCodeExpired">
                          <h2>The Code has been expired!</h2>
                          <div class="content" data-section-name="verification-code-expired">
                            <p>
                              Please contact us to reset this request at adc@arm.gov
                            </p>
                          </div>
                        </div>
                        <div v-else>
                        <div v-if="accountType === 'facility'">
                            <h2>Congratulations! You have successfully created your ARM user facility account</h2>
                            <div class="content" data-section-name="verify-success">
                                <p>
                                    The information you have submitted will be reviewed for accuracy and completion and ARM will contact you if more information is needed for your registration. Please note, access to ARM user facility information and sites resulting from registrations with insufficient or intentionally falsified information will be disabled. Thank you for your cooperation during this process.
                                </p>
                                <h3>Keep up-to-date!</h3>
                                <p>
                                    Stay current on the latest in atmospheric news and research from the ARM user facility and Atmospheric System Research Program.
                                </p>
                                <b-button variant="link" @click="navPage" class="mr-sm-2">Subscribe Now!</b-button>
                            </div>
                        </div>
                      <div v-else-if="accountType === 'poster'">
                        <h2>Congratulations! You have successfully created your Poster account</h2>
                        <div class="content" data-section-name="verify-success">
                          <p>
                            You will receive an email confirming the details of the poster submission. If you require additional assistance, please direct your request to accounts@arm.gov.
                          </p>
                        </div>
                      </div>
                        <div v-else>
                            <h2>Congratulations! You have successfully created your ARM limited user account</h2>
                            <div class="content" data-section-name="verify-success">
                                <p>
                                    You will now receive the latest in atmospheric news and research from the ARM user facility and Atmospheric System Research Program.
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div v-else-if="verified === false">
                        <h2>Error!</h2>
                        <div class="content" data-section-name="account-activate-error">
                            <p class="text-danger">
                              Sorry, an error occurred while activating your account. Please contact adc@arm.gov.
                            </p>
                        </div>
                    </div>
                  <div v-else>
                    <b-spinner label="Spinning"/>
                  </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import axios from "axios";
    import Config from "../config";
    export default {
        name: "ActivateAccount",
        components: {
            TagHeader
        },
        data() {
            return {
                title: "Account Status",
                verified: null,
                accountType: null,
                verificationCode: "",
                showActivateField: true,
                username: "",
                isCodeExpired: null,
            }
        },
        watch: {
            $route (to, from) {
                this.validateToken(to.params.params)
            }
        },
        mounted() {
            this.validateToken(this.$route.params.params)
        },
        methods: {
            validateToken(params) {
                this.username = params.split("/")[0].split("=")[1]
                this.accountType = params.split("/")[1].split("=")[1]
            },
            activateAccount() {
                this.isCodeExpired = null
              this.verified = null
                this.showActivateField = false
                if (!this.username || !this.verificationCode){
                    this.verified = false
                } else {
                  const payload = {
                    username: this.username,
                    verificationCode: this.verificationCode
                  };
                  const self = this;
                  axios({
                    method: "post",
                    url: `${Config.api.url}/users/activate/account`,
                    data: payload
                  }).then((response) => {
                    console.log(response)
                    self.verified = true
                    if (response.data === true) {
                      self.isCodeExpired = false
                    } else {
                      self.isCodeExpired = true
                    }
                  }).catch((error) => {
                    console.log(error)
                    self.verified = false
                  })
                }
            },
            navPage () {
              const username = this.$route.params.params.split("/")[0].split("=")[1];
              this.$router.push({path: `/update/${username}`})
            }
        }
    }
</script>

<style scoped>
    .btn {
        background-color: #00BD70 !important;
        border-color: #00BD70 !important;
    }

</style>
