<template>
    <b-container fluid>
        <TagHeader :title="title" :breadCrumbTitle="breadCrumbTitle"></TagHeader>
        <DashboardContent />
    </b-container>
</template>

<script>
    import TagHeader from "@/components/TagHeader"
    import DashboardContent from "@/components/DashboardContent"
    export default {
        name: "Dashboard",
        components: {
            TagHeader,
            DashboardContent
        },
        data() {
            return {
                title: "Account Management",
                breadCrumbTitle: "Connect with ARM"
            }
      }
    }
</script>

<style>

</style>